import { PlainObject } from '@superset-ui/core';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { Step } from 'react-joyride';

interface AppState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  config?: PlainObject | null;
  joyride?: any;
  overlayClick: boolean;
}

const appState: AppState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  overlayClick: false,
};

export const SqlLabOnboardingContext = createContext({
  state: appState,
  setState: () => undefined,
});

SqlLabOnboardingContext.displayName = 'SqlLabOnboardingContext';

export function SqlLabOnboardingProvider(props: any) {
  const [state, setState] = useState(appState);
  const value = useMemo(() => ({ state, setState }), [state, setState]);
  return <SqlLabOnboardingContext.Provider value={value} {...props} />;
}

export function useSqlLabOnboardingContext(): {
  setState: (
    patch: Partial<AppState> | ((previousState: AppState) => Partial<AppState>),
  ) => void;
  state: AppState;
} {
  const context = useContext(SqlLabOnboardingContext);
  if (!context) {
    throw new Error(
      'useSqlLabOnboardingContext must be used within a SqlLabOnboardingProvider',
    );
  }

  return context;
}
